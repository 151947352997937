import React, { useState } from 'react'
import './form.css'
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { FcApproval } from "react-icons/fc";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Form() {
    const history = useHistory()
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNum: '',
        city: '',
        age: '',
        highSchool: '',
        secondarySchool: '',
        graduation: '',
        skills: '',
        experience: '',
        expectedSalary: '',
        resumelink: ''
    })
    const [file, setFile] = useState();
    const [loader, setLoader] = useState(false);


    const postImage = async (event) => {
        try {
            event.preventDefault()
            if (file) {
                setLoader(true);
                const imageData = new FormData();
                imageData.append("pdf", file);
                const result = await axios.post('https://analytics.funnearn.com/help/api/cv', imageData, {
                    headers: {
                        'Content-Type': "multipart/form-data",
                    },
                })
                let newData = result.data
                if (result.status === 200) {
                    setLoader(false)
                    setFormData({
                        ...formData,
                        resumelink: newData
                    })
                } else {
                    toast.error('Something went wrong', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoader(false)
                }
            }
            else {
                toast.error("Oops! You hadn't selected any file", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        }
        catch (err) {
            toast.error(err, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }
    const { firstName, lastName, email, phoneNum, city, age, highSchool, secondarySchool, graduation, skills, experience, expectedSalary, resumelink } = formData
    const sendMail = async (e) => {
        e.preventDefault();
        let userData = JSON.stringify({ firstName, lastName, email, phoneNum, city, age, highSchool, secondarySchool, graduation, skills, experience, expectedSalary, resumelink })
        if (!resumelink) {
            toast.warn('Please Upload Your Resume !', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else {
            let response = await fetch('https://analytics.funnearn.com/help/api/jpSendMail', {
                method: "POST",
                mode: "cors",
                credentials: "same-origin",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: 'no-referrer',
                body: userData
            })
            if (response.status === 200) {
                await toast.success("Your's Details has been submitted successfully !", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.push('/');
            } else {
                toast.error('Something went wrong', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className='header2'></div>
            <section className='fp_section'>
                <h1 className='cp_heading'>User-Details</h1>
                <form className='fp_form' onSubmit={sendMail}>
                    <div className='fq-firstsec'>
                        <div className='fq_para'>
                            <h6>Enter Your Personal Details</h6>
                            <p>
                                Provide your personal contact details so we can get in touch with you with suitable job findings and career management.

                            </p>
                        </div>
                        <div>
                            <div className='fpic_conatiner'>
                                <div className='fp_inptContainer'><img src={require('../../media/elements/Icon awesome-user-circle.png')} alt='icon' /><input className='fp_inpt' value={formData.firstName} name='firstName' placeholder='FIRST NAME' type={"text"} required onChange={handleChange} /></div>
                                <div className='fp_inptContainer'><img src={require('../../media/elements/Icon awesome-user-circle.png')} alt='icon' /><input className='fp_inpt' value={formData.lastName} name='lastName' placeholder='LAST NAME' type={"text"} required onChange={handleChange} /></div>
                            </div>
                            <div className='fpic_conatiner'>
                                <div className='fp_inptContainer'><img src={require('../../media/elements/icon10.png')} alt='icon' /><input className='fp_inpt' value={formData.email} name='email' placeholder='EMAIL' type={"email"} required onChange={handleChange} /></div>
                                <div className='fp_inptContainer'><img src={require('../../media/elements/phone.png')} alt='icon' /><input className='fp_inpt' value={formData.phoneNum} name='phoneNum' placeholder='PHONE NUMBER' type={"tel"} required onChange={handleChange} /></div>
                            </div>
                            <div className='fpic_conatiner'>
                                <div className='fp_inptContainer'><img src={require('../../media/elements/Icon awesome-city.png')} alt='icon' /><input className='fp_inpt' value={formData.city} name='city' placeholder='CITY' type={"text"} required onChange={handleChange} /></div>
                                <div className='fp_inptContainer'><img src={require('../../media/elements/calendar.png')} alt='icon' /><input className='fp_inpt' value={formData.age} name='age' placeholder='AGE' type={"text"} required onChange={handleChange} /></div>
                            </div>
                        </div>
                    </div>
                    <div className='fq-firstsec'>
                        <div className='fq_para'>
                            <h6>Enter Your Educational Details</h6>
                            <p>
                                Provide us with your educational details and skills to get notified about the job matching your educational criteria.
                            </p>
                        </div>
                        <div>
                            <div className='fpic_conatiner'>
                                <div className='fp_inptContainer'><input className='fp_inpt' value={formData.highSchool} name='highSchool' placeholder='HIGH SCHOOL' type={"text"} required onChange={handleChange} /></div>
                                <div className='fp_inptContainer'><input className='fp_inpt' value={formData.secondarySchool} name='secondarySchool' placeholder='SECONDARY SCHOOL' type={"text"} required onChange={handleChange} /></div>
                            </div>
                            <div className='fpic_conatiner'>
                                <div className='fp_inptContainer'><input className='fp_inpt' value={formData.graduation} name='graduation' placeholder='GRADUATION' type={"text"} required onChange={handleChange} /></div>
                                <div className='fp_inptContainer'><input className='fp_inpt' value={formData.skills} name='skills' placeholder='SKILLS' type={"text"} required onChange={handleChange} /></div>
                            </div>
                        </div>
                    </div>
                    <div className='fq-firstsec'>
                        <div className='fq_para'>
                            <h6>Enter Your Job Details</h6>
                            <p>
                                If you are doing or have done any job in your field, then provide the complete details below.
                            </p>
                        </div>
                        <div>
                            <div className='fpic_conatiner'>
                                <div className='fp_inptContainer'><input className='fp_inpt' value={formData.experience} name='experience' placeholder='EXPERIENCE' type={"text"} required onChange={handleChange} /></div>
                                <div className='fp_inptContainer'><input className='fp_inpt' value={formData.expectedSalary} name='expectedSalary' placeholder='EXPECTED SALARY' type={"text"} required onChange={handleChange} /></div>
                            </div>
                        </div>
                    </div>
                    <div className='fq-firstsec'>
                        <div className='fq_para'>
                            <h6>Upload Your Resume</h6>
                        </div>
                        {
                            !formData.resumelink ?
                                !loader ?
                                    <form className='cvform' >
                                        <input required className='modal_inpt' name='resumeLink' type={"file"} accept="application/pdf" filename={file} onChange={e => setFile(e.target.files[0])} />
                                        <button onClick={postImage} className='fq_upBtn'>Upload Resume</button>
                                    </form> :
                                    <Spinner animation="border" variant="info" />
                                :
                                <>
                                    <div className='success_uploaded'>
                                        <p> <FcApproval />  Resume Uploaded Successfully</p>
                                    </div>
                                </>
                        }
                    </div>
                    <div className='pfbtnContainer'>
                        <button >SUBMIT</button>
                    </div>
                </form>

            </section>

        </>
    )
}

