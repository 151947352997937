import React from 'react';
import './LandinngPage.css'
import { Link } from 'react-router-dom';
import tick from '../media/elements/tick_icon.png'
import { Carousel } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import crsl1 from '../media/pictures/Rectangle.png'
import ContactComponent from '../components/contact/ContactComponent';

const FeatureCard = ({ imgsrc, heading, paratext }) => {
    return (
        <>
            <div className='fc_cards'>
                <img className='fcc_img' src={imgsrc} alt='icons' />
                <h4 className='fcc_h4'>{heading}</h4>
                <p className='fcc_para'>{paratext}</p>
            </div>
        </>
    )
}

export default function LandingPage() {

    return (
        <>
            <div className='lp_heroSection'>
                <div className='lp_rightContainer'>
                    <div className='lp_heroHeading'>Looking for a job? Not sure where to apply? Don’t worry! </div>
                    <div className='lp_heroPara'>Rozgars is here to help you out. Just apply here and find the most suitable job according to your convenience. </div>
                    <div className='lp_linkBtn'><Link to='/form'>Get Started</Link></div>
                </div>
                <img className='lp_coupleImage' src={require('../media/pictures/Girl.png')} alt='icon' />
            </div>
            <div className='lp_servicesSection'>
                <div className='lp_serviceContainer'>
                    <div className='lp_serviceCard'>
                        <div className='lp_serviceImageSection'>
                            <div className='lp_serviceHeading '>Explore Jobs With Rozgars</div>
                            <div className='lp_servicePara'>
                                Rozgars is here to provide you with your dream job. With our online portal, you'll be able to get jobs in reputable companies. Find out how to go ahead and achieve your objectives.
                                <div className='mob_container'>
                                    <div className='mob_tic_para'>
                                        <p className='lp_tickPara'> <img src={tick} alt='tick' /> Simple registration and application procedure.</p>
                                        <p className='lp_tickPara'> <img src={tick} alt='tick' /> Apply online for your convenient job profiles.</p>
                                        <p className='lp_tickPara'> <img src={tick} alt='tick' /> Apply instantly without paperwork.</p>
                                        <p className='lp_tickPara'> <img src={tick} alt='tick' /> Ask for solutions anytime from the team Rozgars.</p>
                                    </div>
                                    <div className='mob_img'><img className='' alt='couple icon' src={require('../media/pictures/sec1_img.png')} /></div>
                                </div>
                            </div>
                        </div>
                        <div className='lp_imageContainer'><img className='lp_imageIcon' alt='couple icon' src={require('../media/pictures/sec1_img.png')} /></div>
                    </div>
                    <div>
                        <div className='lp_serviceCard'>
                            <div className='lp_imageContainer'>
                                <img className='lp_imageIcon' alt='couple icon' src={require('../media/pictures/sec2_img.png')} />
                            </div>
                            <div className='lp_serviceImageSection'>
                                <div className='lp_serviceHeading'>Why choose us?</div>
                                <div className='lp_servicePara'>
                                    Rozgar specialises in the middle to upper leadership roles in various sectors, and our clientele includes both Indian and multinational corporations. You need not worry about not finding a suitable job for you. You will get the best type of job in your field.
                                    <div className='mob_container'>
                                        <div className='mob_tic_para'>
                                            <p className='lp_tickPara'> <img src={tick} alt='tick' /> Job listings straightforwardly from the companies</p>
                                            <p className='lp_tickPara'> <img src={tick} alt='tick' /> Maximum and most factual data</p>
                                            <p className='lp_tickPara'> <img src={tick} alt='tick' /> Minimum chances of duplicates and spam companies</p>
                                            <p className='lp_tickPara'> <img src={tick} alt='tick' /> An updated listing with a direct connection to the companies</p>
                                        </div>
                                        <div className='mob_img'>
                                            <img className='' alt='couple icon' src={require('../media/pictures/sec2_img.png')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lp_successStories'>
                <h1>Features</h1>
                <div className='lp_featureCardC'>
                    <FeatureCard
                        imgsrc={require('../media/pictures/our_service.png')}
                        heading='Our Services'
                        paratext='Providing jobs according to the educational level of every candidate in every sector. Direct connections to the companies and also assist individuals in getting hired digitally with convenience
                        '
                    />
                    <FeatureCard
                        imgsrc={require('../media/pictures/registration.png')}
                        heading='Registration'
                        paratext='Set up a free account to receive interview invitations and jobs that are a good fit for you.
                        Register here and start finding your dream job with ease from your home
                        '
                    />
                    <FeatureCard
                        imgsrc={require('../media/pictures/trust.png')}
                        heading='Trusted'
                        paratext='Verified and reputable company providing jobs to job seekers. 
                        Offers only from the verified companies 
                        '
                    />
                </div>
            </div>
            <div className='carousel_bt'>
                <Carousel>
                    <Carousel.Item interval={1000}>
                        <img
                            className="d-block w-100"
                            src={crsl1}
                            alt="Second slide"
                        />
                        <Carousel.Caption >
                            <div className='c_card'>
                                <img className='cc_imgC' src={require('../media/elements/sakhshi.png')} alt='Personimage' />
                                <p className='carousel_para'>
                                    Rozgars has provided me with excellent service, and I am really pleased. They are available on time and deal with the recruiters professionally and courteously, and provide solutions at any time. I have got many talented candidates for my software development company. Because of Rozgars, my profit has grown, and I will certainly utilize their facilities again.
                                </p>
                                <h5>HR Recruiter</h5>
                                <p className='corouserl_jobPosition'>Reputable Company in Bangalore.</p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={500}>
                        <img
                            className="d-block w-100"
                            src={crsl1}
                            alt="Second slide"
                        />
                        <Carousel.Caption >
                            <div className='c_card'>
                                <img className='cc_imgC' src={require('../media/elements/shresha.png')} alt='Person ' />
                                <p className='carousel_para'>
                                    Rozgars is quite the best place to find your dream job, just by sitting at your home. I have been tired of searching for good companies for a job as a content writer, but here at Rozgars, I found reputable and genuine companies and also got hired by one of them. Appreciable work, thanks to Rozgars.
                                </p>
                                <h5>Reena Gupta</h5>
                                <p className='corouserl_jobPosition'>Content Writer</p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={crsl1}
                            alt="Third slide"
                        />
                        <Carousel.Caption >
                            <div className='c_card'>
                                <img className='cc_imgC' src={require('../media/elements/alwin.png')} alt='Person ' />
                                <p className='carousel_para'>
                                    I had five unchangeable requirements: a firm that excites me, a business that I am enthusiastic about, a role that pushes and expands me in various areas, leadership that supports and enables me to perform my best, and a terrific work atmosphere and team cohesion. All of these criteria are met by Rozgars. A big thanks to him.
                                </p>
                                <h5>Preet Manchanda</h5>
                                <p className='corouserl_jobPosition'>Software Developer</p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <section className='contact_section'>
                <h2 className='cs_heading'>Contact Us</h2>
                <ContactComponent />
            </section>
            {/* <form className='imageform' onSubmit={postImage}>
                <div className='rf_fieldContainer choose_container'>
                    <label className='rf_fields'>Choose profile pic :</label>
                    <input className='input chooseImg' name='profileImage' type={"file"} accept="application/pdf" filename={file} required onChange = {e => setFile(e.target.files[0])} />
                </div>
                <button className='btn fp_btn'  type="submit">Upload</button>
            </form> */}


        </>
    );
}
