import React from 'react'
import ContactComponent from '../../components/contact/ContactComponent'
import './contact.css'

export default function Contact() {
    return (
        <>
            <div className='header2'></div>
            <section className='contact_section'>
                <h1 className='cp_heading'>Contact Us</h1>
                <ContactComponent cName="cp_cc"/>
            </section>
        </>
    )
}
