import React, { useState } from 'react'
import './ContactComponent.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ContactComponent(props) {
    const [query, setQuery] = useState({
        userName: '',
        email: '',
        message: ''
    })

    const sendMail = async () => {
        if (query.userName && query.email && query.message) {

            let userData = JSON.stringify({ query })
            let response = await fetch('https://analytics.funnearn.com/help/api/jpContact', {
                method: "POST",
                mode: "cors",
                credentials: "same-origin",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: 'no-referrer',
                body: userData
            })
            if (response.status === 200) {
                toast.success('Your Query has been successfully sent', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setQuery({
                    userName: "",
                    email: "",
                    message: ""
                })
            } else {
                toast.error('Opos, Something went wrong!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                alert("");
            }
        }
        else {
            toast.warn('Please fill all the field!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }



    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setQuery({
            ...query,
            [name]: value
        })
    }
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={`contact_component ${props.cName}`}>
                <div className='cc_upperContainer'>
                    <h4 >We are here for you</h4>
                    <p className='ccuc_para'>
                        If you have any queries or want to give feedback, you can contact us anytime.
                    </p>
                </div>
                <div className='cc_lowerContainer'>
                    <div className='cc_inputContainer'>
                        <div className='cc_inptCntnr'><img src={require('../../media/elements/Icon awesome-user-circle.png')} alt="circle" /><input value={query.userName} name='userName' className='cc_inpt' type='Enter job Type' placeholder='Full Name' required onChange={handleChange} /></div>
                        <div className='cc_inptCntnr'><img src={require('../../media/elements/icon10.png')} alt='email_icon' /><input value={query.email} name='email' className='cc_inpt' type='email' placeholder='Email' required onChange={handleChange} /></div>
                    </div>
                    <textarea value={query.message} name='message' placeholder='Write Something ...' required onChange={handleChange}></textarea>
                    <button onClick={sendMail}> Submit</button>
                </div>
            </div>
        </>
    )
}

