import React, { useState } from 'react';
// import { FaBars } from "react-icons/fa";
import './Navbar.css'
import { Link } from 'react-router-dom';

export default function Navbar() {
    const [toggle, setToggle] = useState(false);
    const [showToggle, setShowToggle] = useState(true);
    const toggler = () => {
        setToggle(true)
        setShowToggle(false)
        document.body.style.overflow = "hidden"

    }
    const removeToggler = () => {
        setToggle(false)
        setShowToggle(true)
        document.body.style.overflow = "scroll"
    }
    return (
        <>
            <div className='nav_container'>
                <Link to='/' className='nav_logo_container'>
                    <img className='nav_logo' alt='logo' src={require('../../media/elements/logo.png')} />
                </Link>
                <div className='nav_link_container'>
                    <Link to='/' className='nav_link'>Home</Link>
                    <Link to='/about' className='nav_link'>About Us</Link>
                    {/* <Link to='/agents' className='nav_link'>Form-Details</Link> */}
                    <Link to='/contact' className='nav_link'>Contact Us</Link>
                </div>
                <div className='mob_nav'>
                    <div >
                        {showToggle ? <img src={require('../../media/elements/menu.png')} className='nav_toggle' onClick={toggler} alt='icon' /> : <></>}
                    </div>
                    {
                        toggle ?
                            <div className='nav_toggle_div'>
                                <div className='nav_closer' onClick={removeToggler}>X</div>
                                <div className='nav_mob_link'>
                                    <Link to='/' className='toggle_link' onClick={removeToggler}>Home</Link>
                                    <Link to='/about' className='toggle_link' onClick={removeToggler}>About Us</Link>
                                    <Link to='/contact' className='toggle_link' onClick={removeToggler}>Contact Us</Link>
                                </div>
                            </div >
                            : ''
                    }
                </div>
            </div>

        </>
    )
}
