import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import './App.css';
import LandingPage from './pages/LandingPage';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Form from './pages/form/Form';


export default function App() {
  return (
    <>
      <BrowserRouter >
        <Navbar />
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/about' component={About} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/form' component={Form} />
          {/*
            <Route path = '/details/:id' component={UserProfile}/>
            <Route path = '/pro' component={ProPage}/> */}
          <Redirect exact from="/" to="/" />
        </Switch>
        <Footer />

      </BrowserRouter>
    </>
  )
}
