import React from 'react'
import './about.css'

export default function About() {
    return (
        <>
            <div className='header2'></div>
            <div className='about_section'>
                <h1 className='About_heading'>About-Us</h1>
                <p className='about_para'>
                    Rozgar is an online job search engine for job seekers and companies
                    looking for top talent. We will help you and show you a way to job
                    finding, recruitment, career management, and personnel management
                    solutions.<br />
                </p>
                <div className='about_divs'>
                    <div className='ad_contaner ad_contaner1'>
                        <h4 className='adc_heading'>Team Management</h4>
                        <p className='adc_para'>
                            We have a marketing team of dedicated experts that, utilizing their
                            exact search expertise, comprehend and adapt to the unique personnel
                            demands of our clients. As a result, we supply organizations with
                            outstanding manpower and job searchers with their dream job.
                        </p>
                    </div>
                    <div className='ad_contaner'>
                        <h4 className='adc_heading'>Our Contract</h4>
                        <p className='adc_para'>
                            Helping people discover their purpose necessitates a highly skilled
                            team. We assist you in working securely and getting paid on time,
                            from contract through payment. Our goal is to assist the client in
                            enhancing their strategic benefits.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
